import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH =
	'M16,24.727L17.538,23.189L11.451,17.091L24.727,17.091L24.727,14.909L11.451,14.909L17.538,8.811L16,7.273L7.273,16L16,24.727Z';

export const ArrowBackIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} />;
};
