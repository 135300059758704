import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH = 'M15 22H17V20H15V22ZM16 6C10.48 6 6 10.48 6 16C6 21.52 10.48 26 16 26C21.52 26 26 21.52 26 16C26 10.48 21.52 6 16 6ZM16 24C11.59 24 8 20.41 8 16C8 11.59 11.59 8 16 8C20.41 8 24 11.59 24 16C24 20.41 20.41 24 16 24ZM16 10C13.79 10 12 11.79 12 14H14C14 12.9 14.9 12 16 12C17.1 12 18 12.9 18 14C18 16 15 15.75 15 19H17C17 16.75 20 16.5 20 14C20 11.79 18.21 10 16 10Z';

const SupportIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} />;
};

export default SupportIcon;
