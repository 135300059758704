import React, { useState } from 'react';
import styled from 'styled-components/native';
import { gql, useQuery } from '@apollo/client';
import { StyleSheet, CheckBox, Dimensions, Text, Platform, Image, View } from 'react-native';
import { DataTable } from 'react-native-paper';

import * as Styled from './styled';
import { Title } from '../../components/ui';

const Container = styled.View`
	backgroundColor: ${({ theme }) => theme.components.screen.background};
	min-height: 100%;
	padding: 10px;
`;

const DataTableContainer: React.FC = ({ children: table }) => (
	<Styled.DataTableContainer>
		<Styled.DataTableTitleContainer>
			<Title size='medium'>9205 Trackers</Title>
		</Styled.DataTableTitleContainer>
		<Styled.Separator />
    {table}
	</Styled.DataTableContainer>
);

const Check = (props : Props  ) => {

  console.log("Check", props);

  return (
    <View style={styles.container}>
      <View style={styles.checkboxContainer}>
        <CheckBox
          value={props.props.isChecked1}
          onValueChange={props.props.setSelection1}
          style={styles.checkbox}
        />
        <Text style={styles.label}>9205-Tracker-SanFrancisco-1</Text>
      </View>
      <View style={styles.checkboxContainer}>
        <CheckBox
          value={props.props.isChecked2}
          onValueChange={props.props.setSelection2}
          style={styles.checkbox}
        />
        <Text style={styles.label}>9205-Tracker-SanFrancisco-3</Text>
      </View>
    </View>
  );
};

interface Props {
  isChecked1 : boolean;
  isChecked2 : boolean;
  setSelection1 : (value) => void;
  setSelection2 : (value) => void;
};

export const DeviceScreen: React.FC<Props> = (props: Props) => {
	return (
		<Styled.Container>
        <DataTableContainer>
          <Check props={props}/>
        </DataTableContainer>
		</Styled.Container>
	);
};

const styles = StyleSheet.create({
  table: {
    color: 'white',
    backgroundColor: '#07213D',
    width: '100%',
  },
  statusCell: {
    flex: 0.5,
    color: 'white',
    backgroundColor: 'white',
  },
  container: {
    flex: 1,
    margin: 8,
    //alignItems: "center",
    //justifyContent: "center",
  },
  checkboxContainer: {
    flexDirection: "row",
    marginBottom: 20,
  },
  checkbox: {
    alignSelf: "center",
  },
  label: {
    margin: 8,
    color: 'white'
  },
});