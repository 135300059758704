import React, { useRef } from 'react';
import { useState } from 'react';
import { View } from 'react-native';
import { Content } from './content';
import * as Styled from './styled';
import { Trigger } from './trigger';
import { useOnClickOutside } from '../../../../../hooks/use-on-click-outside.web';

export const ProfileDropdown: React.FC = () => {
	const [open, setOpen] = useState(false);
	const dropdownRef = useRef<View>(null);
	useOnClickOutside(dropdownRef, () => {
		setOpen(false);
	});

	return (
		<Styled.Container ref={dropdownRef}>
			<Trigger onPress={() => setOpen(!open)} active={open} />
			<Styled.Body visible={open}>
				<Content setOpen={setOpen} />
			</Styled.Body>
		</Styled.Container>
	);
};
