import React from 'react';
import { ReactElement } from 'react';
import {
	DocumentIcon,
	SupportIcon,
	VitalsIcon,
	MonitorIcon,
	EditIcon,
	SettingsIcon, 
	IntegrationsIcon,
	LocationIcon
} from '../ui';
import { HomeScreen, VitalsScreen, StyleGuide, MapScreen } from '../../screens';
import { Link, LinksList } from '../ui/layout/nav-bar/types';
import { openExternalLink } from './open-external-link';

interface NavRoute {
	title: string;
	icon: ({ size, color }: { size: number; color: string }) => ReactElement;
	name: string;
	component: React.FC;
}

export const NAV_ROUTES: NavRoute[] = [
	{
		title: 'Vitals',
		name: 'Vitals',
		icon: ({ size, color }) => <VitalsIcon size={`${size}px`} color={color} />,
		component: VitalsScreen
	},
	{
		title: 'Home',
		icon: ({ size, color }) => <MonitorIcon size={`${size}px`} color={color} />,
		name: 'Home',
		component: HomeScreen
	},
	// {
	// 	name: 'StyleGuide',
	// 	title: 'Style Guide',
	// 	icon: ({ size, color }) => <EditIcon size={`${size}px`} color={color} />,
	// 	component: StyleGuide
	// },
	{
		name: 'MapScreen',
		title: 'Live Tracking',
		icon: ({ size, color }) => <LocationIcon size={`${size}px`} color={color} />,
		component: MapScreen
	},
	{
		name: 'Integrations',
		title: 'Integrations',
		icon: ({ size, color }) => <IntegrationsIcon size={`${size}px`} color={color} />,
		component: HomeScreen
	},
	{
		name: 'Settings',
		title: 'Settings',
		icon: ({ size, color }) => <SettingsIcon size={`${size}px`} color={color} />,
		component: StyleGuide
	},
];

export const EXTRA_ROUTES: LinksList[] = [
	{
		links: [
			{
				key: 'docs',
				title: 'Docs',
				href: 'https://particle.io',
				icon: () => <DocumentIcon size='36px' />
			},
			{
				key: 'support',
				title: 'Support',
				href: 'https://particle.io',
				icon: () => <SupportIcon size='36px' />
			}
		],
		onLinkPress: async (link: Link): Promise<void> => {
			if (!('href' in link)) {
				return;
			}

			await openExternalLink(link.href);
		}
	}
];
