import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH =
	'M24.684,18.701C23.173,22.153 19.727,24.578 15.722,24.578C11.713,24.578 8.264,22.16 6.756,18.703C6.485,18.085 5.765,17.802 5.146,18.072C4.528,18.342 4.245,19.062 4.515,19.681C6.401,24.001 10.711,27.022 15.722,27.022C20.728,27.022 25.035,23.994 26.923,19.683C27.194,19.065 26.912,18.343 26.294,18.072C25.676,17.802 24.955,18.083 24.684,18.701ZM16,6.222C12.961,6.222 10.5,8.683 10.5,11.722C10.5,14.761 12.961,17.222 16,17.222C19.039,17.222 21.5,14.761 21.5,11.722C21.5,8.683 19.039,6.222 16,6.222ZM16,8.834C17.595,8.834 18.887,10.127 18.887,11.722C18.887,13.317 17.595,14.609 16,14.609C14.405,14.609 13.112,13.317 13.112,11.722C13.112,10.127 14.405,8.834 16,8.834Z';

const ProfileIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} />;
};

export default ProfileIcon;
