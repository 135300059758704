import React, { useEffect, useState, useRef } from 'react';
import { useTheme } from 'styled-components/native';
import { VictoryChart, VictoryLine, VictoryAxis } from 'victory';
import { MultiLineChartType } from './types';
import { sampleData } from './sampleData';
import { MAX_DATA_POINTS, MAX_TICKS_WEB } from './constants';

export const MultiLineChart: MultiLineChartType = ({
	initialWidth = 0,
	height,
	data,
	getX,
	formatTick,
	dataAccessors,
	maxDataPoints = MAX_DATA_POINTS,
	maxTicks = MAX_TICKS_WEB
}) => {
	const theme = useTheme();
	const containerRef = useRef<HTMLDivElement>(null);
	const [width, setWidth] = useState(initialWidth);
	useEffect(() => {
		// TODO(carlos): Debounce this handler
		const handleResize = () => {
			setWidth(containerRef.current?.clientWidth || initialWidth);
		};
		setWidth(containerRef.current?.clientWidth || initialWidth);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	const sampledData = sampleData(data, maxDataPoints);
	const ticks = sampleData(sampledData, maxTicks).map(getX);

	return (
		<div
			style={{
				height: `${height}px`
			}}
			ref={containerRef}
		>
			<VictoryChart
				width={width}
				theme={theme.charts}
				domainPadding={{ y: 50 }}
				padding={{ bottom: 70, top: 30, left: 0 }}
			>
				<VictoryAxis tickFormat={formatTick} tickValues={ticks} />
				<VictoryAxis dependentAxis />
				{dataAccessors.map((accessor) => (
					<VictoryLine
						key={accessor.accessorKey.toString()}
						style={{
							data: {
								stroke: accessor.color
							}
						}}
						data={sampledData}
						y={accessor.accessorKey.toString()}
						x={getX}
					/>
				))}
			</VictoryChart>
		</div>
	);
};
