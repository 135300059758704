import { RefObject, useEffect } from 'react';
import { View } from 'react-native';

const isDescendant = (parent: Element, child: Element): boolean => {
	let node = child.parentNode;
	while (node !== null) {
		if (node === parent) {
			return true;
		}
		node = node.parentNode;
	}
	return false;
};

export const useOnClickOutside = (
	ref: RefObject<View>,
	callback: () => void
): void => {
	useEffect(() => {
		const handlePressOut = (evt: MouseEvent) => {
			if (
				ref.current !== null &&
				evt.target !== null &&
				isDescendant(ref.current as unknown as Element, evt.target as Element)
			) {
				return;
			}
			callback();
		};

		document.body.addEventListener('click', handlePressOut);
		return () => {
			document.body.removeEventListener('click', handlePressOut);
		};
	}, [ref]);
};
