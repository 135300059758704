import styled from 'styled-components/native';
import { ButtonContainer, ButtonContent } from '../button';


export const PrimaryButtonContainer = styled(ButtonContainer)`
	backgroundColor: ${({ theme }) => theme.components.buttons.primary.background};
	border-radius: 8px;
`;

export const PrimaryButtonContent = styled(ButtonContent)`
	color: ${({ theme }) => theme.components.buttons.primary.color};
`;
