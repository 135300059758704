import styled from 'styled-components/native';


export const Container = styled.ScrollView`
	background-color: ${({ theme }) => theme.components.screen.background};
	min-height: 100%;
	padding: 23px;
`;

export const DataTableContainer = styled.View`
	padding: 17px 0;
	backgroundColor: ${({ theme }) => theme.colors.primary};
	borderRadius: 12px;
`;

export const DataTableTitleContainer = styled.View`
	paddingBottom: 17px;
	paddingLeft: 17px;
`;

export const DataTableSearchContainer = styled.View`
	padding: 17px;
`;

export const Separator = styled.View`
	height: 1px;
	backgroundColor: #284767;
	borderRadius: 5px;
`;
