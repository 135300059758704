import { KeyBasedDataItem } from './types';

export const sampleData = <T extends KeyBasedDataItem>(
	data: T[],
	maxDataPoints: number
): T[] => {
	if (data.length <= maxDataPoints) {
		return data;
	}

	const k = Math.ceil(data.length / maxDataPoints);
	return data.filter(
		(_, index) => data.length <= maxDataPoints || index % k === 0
	);
};
