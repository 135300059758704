import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH =
	'M16,21.664L17.632,22.736L16,30.4L14.352,22.736L16,21.664ZM26.176,5.808L19.6,16L26.176,26.176L16,19.6L5.808,26.176L12.4,16L5.808,5.808L16,12.4L26.176,5.808ZM30.4,16L22.72,17.648L21.664,16L22.72,14.352L30.4,16ZM9.264,14.352L10.32,16L9.264,17.648L1.6,16L9.264,14.352ZM16,1.6L17.632,9.264L16,10.32L14.352,9.264L16,1.6Z';

export const ParticleMarkIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} />;
};
