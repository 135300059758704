import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH = 'M26 17.4286H17.4286V26H14.5714V17.4286H6V14.5714H14.5714V6H17.4286V14.5714H26V17.4286Z';

const AddIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} />;
};

export default AddIcon;
