import styled from 'styled-components/native';
import Type from '../type';
import { ParagraphProps } from './types';


export const Paragaraph = styled(Type)<ParagraphProps>`
	fontSize: ${({ size }) => {
		switch (size) {
			case 'regular': return '16px';
			case 'small': return '14px';
		}
	}};
	fontFamily: Inter_400Regular;
`;
