import styled from 'styled-components/native';
import { BodyProps, ItemProps, TriggedProps } from './types';

export const Container = styled.View`
	position: relative;
`;

export const Trigger = styled.TouchableOpacity<TriggedProps>`
	background-color: ${(props) =>
		props.active
			? props.theme.components.profileDropdown.background
			: 'transparent'};
	padding: 4px;
	border-radius: 8px;
`;

export const IconBackround = styled.View`
	width: 24px;
	height: 24px;
	border-radius: 12px;
	background-color: ${(props) =>
		props.theme.components.profileDropdown.iconBackground};
`;

export const Body = styled.View<BodyProps>`
	position: absolute;
	top: 100%;
	right: 0;
	margin-top: 4px;
	display: ${(props) => (props.visible ? 'flex' : 'none')};
`;

export const Content = styled.View`
	width: 230px;
	/* height: 100px; */
	height: auto;
	background-color: ${(props) =>
		props.theme.components.profileDropdown.background};
	border-radius: 12px;
`;

export const Item = styled.TouchableOpacity<ItemProps>`
	flex-direction: row;
	align-items: center;
	padding: 14px;
	border-bottom-width: ${(props) => (props.last ? '0px' : '1px')};
	border-color: ${(props) =>
		props.theme.components.profileDropdown.dividerColor};
`;

export const ItemLeading = styled.View`
	padding-right: 14px;
`;
