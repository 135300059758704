import styled from 'styled-components/native';
import { ButtonContainerProps, ButtonContentProps } from './types';


// TODO (jaime): Add Font to component
export const ButtonContainer = styled.TouchableHighlight<ButtonContainerProps>`
	backgroundColor: white;
	textAlign: center;
`;

export const ButtonContent = styled.Text<ButtonContentProps>`
	padding: 7px 20px;
	fontFamily: Inter_400Regular;
	fontSize: ${({ size }) => {
		switch (size) {
			case 'medium':
				return '14px';
			case 'large':
				return '18px';
		}
	}};
`;

