import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH = 'M26 12.8182C26 13.8182 25.1818 14.6364 24.1818 14.6364C24.0182 14.6364 23.8636 14.6182 23.7182 14.5727L20.4818 17.8C20.5273 17.9455 20.5455 18.1091 20.5455 18.2727C20.5455 19.2727 19.7273 20.0909 18.7273 20.0909C17.7273 20.0909 16.9091 19.2727 16.9091 18.2727C16.9091 18.1091 16.9273 17.9455 16.9727 17.8L14.6545 15.4818C14.5091 15.5273 14.3455 15.5455 14.1818 15.5455C14.0182 15.5455 13.8545 15.5273 13.7091 15.4818L9.57273 19.6273C9.61818 19.7727 9.63636 19.9273 9.63636 20.0909C9.63636 21.0909 8.81818 21.9091 7.81818 21.9091C6.81818 21.9091 6 21.0909 6 20.0909C6 19.0909 6.81818 18.2727 7.81818 18.2727C7.98182 18.2727 8.13636 18.2909 8.28182 18.3364L12.4273 14.2C12.3818 14.0545 12.3636 13.8909 12.3636 13.7273C12.3636 12.7273 13.1818 11.9091 14.1818 11.9091C15.1818 11.9091 16 12.7273 16 13.7273C16 13.8909 15.9818 14.0545 15.9364 14.2L18.2545 16.5182C18.4 16.4727 18.5636 16.4545 18.7273 16.4545C18.8909 16.4545 19.0545 16.4727 19.2 16.5182L22.4273 13.2818C22.3818 13.1364 22.3636 12.9818 22.3636 12.8182C22.3636 11.8182 23.1818 11 24.1818 11C25.1818 11 26 11.8182 26 12.8182Z';

const MonitorIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} />;
};

export default MonitorIcon;
