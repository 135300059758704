import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Col, Row } from 'react-native-easy-grid';
import { ConnectivityLevelRowsProps, Connectivity } from './types';
import { useDeviceQuery, OrderBy } from '../../../../hooks/use-device';
import { Title } from '../../../ui';
import * as Styled from './styled';


const ConnectivityLevelRows: React.FC<ConnectivityLevelRowsProps> = ({
	columns,
	onSort
}) => {
	const [orderBy, setOrderBy] = useState<OrderBy>({ deviceName: 'asc' });
	const { loading, error, data } = useDeviceQuery({ orderBy });

	useEffect(() => {
		setOrderBy({
			deviceName: onSort.order
		});
	}, [onSort]);

	if (loading) {
		return <Title size='large'>...Loading</Title>;
	}

	if (error) {
		return <Title size='large'>Error</Title>;
	}

	if (!data) {
		return <Title size='large'>No available data :(</Title>;
	}

	const connectivityData = data.fleetConnectivity?.connectivity;

	return (
		<View style={{ margin: '0 17px' }}>
			{
				connectivityData && connectivityData.map((device: Connectivity, index: number) => {
					return (
						<Row key={index} size={3}>
							{
								columns.map((column, index) => {
									return (
										<Col key={index} style={{ flex: column.size }}>
											<Styled.Cell>
												{column.render(device)}
											</Styled.Cell>
										</Col>
									);
								})
							}
						</Row>
					);
				})
			}
		</View>
	);
};

export default ConnectivityLevelRows;
