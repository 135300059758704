import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH = 'M16.2051 26C17.3333 26 18.2564 25.0769 18.2564 23.9487H14.1538C14.1538 25.0769 15.0769 26 16.2051 26ZM22.359 19.8462V14.7179C22.359 11.5692 20.6872 8.93333 17.7436 8.2359V7.53846C17.7436 6.68718 17.0564 6 16.2051 6C15.3538 6 14.6667 6.68718 14.6667 7.53846V8.2359C11.7333 8.93333 10.0513 11.559 10.0513 14.7179V19.8462L8 21.8974V22.9231H24.4103V21.8974L22.359 19.8462ZM20.3077 20.8718H12.1026V14.7179C12.1026 12.1744 13.6513 10.1026 16.2051 10.1026C18.759 10.1026 20.3077 12.1744 20.3077 14.7179V20.8718Z';

const NotificationsIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} />;
};

export default NotificationsIcon;
