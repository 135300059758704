import { StyledProps } from 'styled-components';
import styled from 'styled-components/native';

type Size = 'tiny' | 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge';
type Direction = 'horizontal' | 'vertical';
interface SpacerProps {
	direction: Direction;
	size: Size;
}

const getWidth = ({ direction, size, theme }: StyledProps<SpacerProps>) => {
	if (direction === 'vertical') {
		return '1px';
	}
	return `${theme.spacing[size]}px`;
};
const getHeight = ({ direction, size, theme }: StyledProps<SpacerProps>) => {
	if (direction === 'horizontal') {
		return '1px';
	}
	return `${theme.spacing[size]}px`;
};

export const Spacer = styled.View<SpacerProps>`
	width: ${getWidth};
	height: ${getHeight};
`;
