import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH = 'M6 16L7.7625 17.7625L14.75 10.7875L14.75 26L17.25 26L17.25 10.7875L24.2375 17.7625L26 16L16 6L6 16Z';

const UpArrowIcon: React.FC<IconProps> = ({ size, color, style }) => {
	return <Icon style={style} path={ICON_PATH} size={size} color={color} />;
};

export default UpArrowIcon;
