import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Constants from 'expo-constants';
import AsyncStorage from '@react-native-async-storage/async-storage';
import fetch from 'cross-fetch';
import { ApolloLink } from "apollo-link";

export interface HeaderContext {
	headers:{
		authorization: string
	}
}
const httpLink = createHttpLink({
	uri: Constants.manifest.extra?.apiURL,
	credentials: 'same-origin', // include,
	fetch
});


const httpLinkHACK = createHttpLink({
	//this is the double-wrapped version of the API gateway endpoint that includes okta auth and CORS
	uri: 'https://5uj1nyf8b4.execute-api.us-east-1.amazonaws.com/dev/graphql'
  });


type AuthLinkContextParams = (firstArg: any, { headers }: any) => Promise<HeaderContext>; // eslint-disable-line @typescript-eslint/no-explicit-any


export const authLinkContext: AuthLinkContextParams = async (_, { headers }) => {
	const token = await AsyncStorage.getItem(Constants.manifest.extra?.tokenConstant);
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
		}
	};
};


const splitLink = ApolloLink.split(
	operation => operation.getContext().clientName === "hack",
	// the string "third-party" can be anything you want,
	// we will use it in a bit
	httpLinkHACK, // <= apollo will send to this if clientName is "third-party"
	httpLink // <= otherwise will send to this
  );


export const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: setContext(authLinkContext).concat(splitLink)
});

