import React from 'react';
import { useState } from 'react';
import * as Styled from './styled';

interface SegmentedControlItem {
	id: string;
	label: string;
	onPress: () => boolean;
}

interface SegmentedControlProps {
	initialItemId: string;
	items: SegmentedControlItem[];
}

export const SegmentedControl: React.FC<SegmentedControlProps> = ({
	items,
	initialItemId
}) => {
	const [activeId, setActiveId] = useState(initialItemId);

	return (
		<Styled.Container>
			{items.map((item, index) => {
				const active = item.id === activeId;
				const last = index === items.length - 1;
				return (
					<Styled.Item
						key={item.id}
						active={active}
						last={last}
						onPress={() => {
							if (item.onPress()) {
								setActiveId(item.id);
							}
						}}
					>
						<Styled.Label type='bold' active={active}>
							{item.label}
						</Styled.Label>
					</Styled.Item>
				);
			})}
		</Styled.Container>
	);
};
