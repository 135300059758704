import React from 'react';
import * as Styled from './styled';
import { TriggedProps } from './types';
import { ProfileIconWithBackground } from './profile-icon-with-background';
import { TEST_IDS } from './constants';

export const Trigger: React.FC<TriggedProps> = ({ onPress, active }) => (
	<Styled.Trigger onPress={onPress} active={active} testID={TEST_IDS.trigger}>
		<ProfileIconWithBackground />
	</Styled.Trigger>
);
