import React, { useState } from 'react';
import { makeRedirectUri, ResponseType, useAuthRequest, useAutoDiscovery, TokenResponse } from 'expo-auth-session';
import { getQueryParams } from 'expo-auth-session/build/QueryParams';
import { DiscoveryDocument } from 'expo-auth-session/build/Discovery';
import * as AuthSession from 'expo-auth-session';
import { maybeCompleteAuthSession } from 'expo-web-browser';
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import { UserAuth } from '../types/user-auth';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { requestOktaAuth, setAccessTokenAndUser } from '../lib/okta-auth';
import { useEffect } from 'react';
import { View, Text, Button, StyleSheet, Image } from 'react-native';
import colors from '../components/ui/colors/colors';

export interface AuthContextType {
	token: string | null,
	error: boolean,
	setToken?: ((token: string | null) => Promise<void>),
	loading: boolean,
	requestAuth: (() => Promise<void>);
	user: UserAuth | null,
	setUser?: ((user: UserAuth | null) => Promise<void>),
	setError?: ((error: boolean) => Promise<void>),
	logout: (() => Promise<void>);
}

export interface AutomaticLoginParamsType {
	discovery: DiscoveryDocument | null,
	request: AuthSession.AuthRequest | null,
	setToken: (token: string | undefined) => Promise<void>,
	setUser: (user: UserAuth | null) => Promise<void>,
	navigateTo: (url: string) => void
}

const initialAuthContext = {
	token: null,
	error: false,
	user: null,
	loading: false,
	requestAuth: /* istanbul ignore next: */ async (): Promise<void> => {
		/* istanbul ignore next: this function are not called just needs to initialize the whole object */
		console.log('');
	},
	logout:/* istanbul ignore next: */  async (): Promise<void> => {
		/* istanbul ignore next: this function are not called just needs to initialize the whole object */
		console.log('');
	}
};

export const AuthContext = React.createContext<AuthContextType>(initialAuthContext);
export const useProxy = Platform.select({ web: false, default: true });

export const navigateTo = (url: string): void => {
	//window.location.replace(url);
};

export const logInAutomatically =
	async ({ discovery, request, setToken, setUser, navigateTo }: AutomaticLoginParamsType): Promise<void> => {
		if( !useProxy ) {
			const { params } = getQueryParams(window.location.href);
			console.log("logInAutomatically");
			if (params.access_token) {
				const authentication = TokenResponse.fromQueryParams(params);
				const accessToken = authentication?.accessToken;
				await setAccessTokenAndUser({ accessToken, setToken, setUser });
				return;
			}
		}

		if (discovery && request) {
			const authUrl = await request.makeAuthUrlAsync(discovery);
			navigateTo(authUrl);
		}
	};


const AuthProvider: React.FC = ({ children }) => {
	if (Platform.OS === 'web') {
		maybeCompleteAuthSession();
	}

	const [token, setTokenState] = useState<string|null>(null);
	const [error, setErrorState] = useState<boolean>(false);
	const [user, setUserState] = useState<UserAuth|null>(null);

	const [doLogin, setDoLogin] = useState<boolean>(false);

	useEffect(() => {
		AsyncStorage.getItem(Constants.manifest.extra?.tokenConstant).then(async (accessToken: string | null) => {
			await setAccessTokenAndUser({ accessToken, setToken, setUser });
		});
	}, []);


	const discovery = useAutoDiscovery(
		Constants.manifest.extra?.oktaAuth?.discovery
	);

	// Request
	const isWeb = Platform.select({ web: true, default: false });
	const protocol = Constants.manifest.extra?.oktaAuth?.runLocal ? 'http://' : 'https://';
	const redirectUri = isWeb ? (protocol + window.location.host) : AuthSession.makeRedirectUri({useProxy});

	console.log("auth:", Constants.manifest.extra?.oktaAuth);
	console.log("redirectUri", redirectUri);

	console.log("user", user);

	const [request, response, promptAsync] = useAuthRequest( // eslint-disable-line @typescript-eslint/no-unused-vars
		{
			clientId: Constants.manifest.extra?.oktaAuth?.clientId,
			scopes: ['openid', 'profile'],
			responseType: AuthSession.ResponseType.Token,
			usePKCE: true,
			extraParams: {
				nonce: 'nonce',
			},
			redirectUri,
		},
		discovery
	);

	const setToken = async (token: string | null | undefined) => {
		console.log("setToken", token);
		if (token) {
			setTokenState(token);
			await AsyncStorage.setItem(Constants.manifest.extra?.tokenConstant, token);
		} else {
			setTokenState(null);
		}
	};

	const setUser = async (user: UserAuth | null) => {
		console.log("setUser", user);
		if (user) {
			setUserState(user);
		} else {
			setUserState(null);
		}
	};

	const setError = async (error: boolean) => {
		setErrorState(error);
	};

	if (!token && doLogin) {
		//logInAutomatically({ discovery, request, setToken, setUser, navigateTo });
		//setDoLogin(false);
	}

	const logout = async() => {
		console.log("logout - remove token");
		await AsyncStorage.removeItem(Constants.manifest.extra?.tokenConstant);
		await setAccessTokenAndUser({ accessToken: null, setToken, setUser });
	};

	function SignInScreen() {
		//const { signIn } = React.useContext(AuthContext);
		const isWeb = Platform.select({ web: true, default: false });
		
		const redirectUri = isWeb ? ('https://' + window.location.host) : makeRedirectUri({useProxy});
		
		// Endpoint
		const discovery = useAutoDiscovery(Constants.manifest.extra?.oktaAuth?.discovery);
		// Request
		const [request, response, promptAsync] = useAuthRequest(
			{
			clientId: Constants.manifest.extra?.oktaAuth?.clientId,
			//clientId: '0oa2p7uyto6ebgVxE5d6',
			scopes: ['openid', 'profile'],
			responseType: ResponseType.Token,
			// In order to follow the "Authorization Code Flow" to fetch token after authorizationEndpoint
			// this must be set to false
			usePKCE: true,
			//usePKCE: false,
			extraParams: {
						nonce: 'nonce',
					},
			redirectUri,
			},
			discovery
		);
		
		React.useEffect(() => {
			if (response?.type === 'success') {
		
				async function fetchTheOkdaAccessToken(response) {
					console.log("fetchTheOkdaAccessToken", response)
		
					const authentication = TokenResponse.fromQueryParams(response.params);
					const accessToken = authentication?.accessToken;

					console.log("authentication", authentication);
					console.log("accessToken", accessToken);
		
					await setAccessTokenAndUser({ accessToken, setToken, setUser });
				}
		
				fetchTheOkdaAccessToken(response);
			}
			console.log('response', response);
		}, [response]);
		
		return (
			<View style={styles.container}>
				<Text style={styles.text}>Aware</Text>
				<Text style={styles.text}></Text>
				<Text style={styles.text}></Text>
				<Text style={styles.text}></Text>
				<Button 
					disabled={!request}
					title="Login"
					onPress={() => {
					promptAsync({ useProxy });
					}}
				/>
			</View>
		);
	}

	//const requestAuth = requestOktaAuth({ promptAsync, useProxy, setError, setToken, setUser });

	const isLoggedIn = token !== null;

	console.log("isLoggedIn", isLoggedIn, "token", token )

	const doLoginFunc = async() => {
		console.log("doLoginFunc:", request);
		promptAsync({useProxy});
		console.log("doLoginFunc done!");
	}

	return (
		<React.Fragment>
			{isLoggedIn ? (
			<AuthContext.Provider value={{
				token,
				setToken,
				loading: !!request,
				error,
				user,
				setUser,
				setError,
				logout }}>
				{ children }
			</AuthContext.Provider> )
			: (
				<SignInScreen setToken={setToken} setUser={setUser} />
			)}
		</React.Fragment>
	);
};

//requestAuth: null, //requestAuth.requestAuthResolve,

export default AuthProvider;



// function RootNavigator({isLoggedIn, isSignOut}) {
// 	return (
// 	  <Stack.Navigator screenOptions={{headerShown: false}}>
// 		{isLoggedIn ? (
// 		  <>
// 			<Stack.Screen name="Root" component={DrawerNavigator} />
// 		  </>
// 		) : (
// 		  <Stack.Screen
// 			name="SignIn"
// 			component={SignInScreen}
// 			options={{
// 			  title: 'Sign in',
// 			  // When logging out, a pop animation feels intuitive
// 			  // You can remove this if you want the default 'push' animation
// 			  animationTypeForReplace: isSignOut ? 'pop' : 'push',
// 			}}
// 		  />
// 		)}
// 		<Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
// 	  </Stack.Navigator>
// 	);
//   }
  


const styles = StyleSheet.create({
	container: {
	  flex: 1,
	  height: '100%',
	  width: '100%',
	  justifyContent: 'center',
	  alignItems: 'center',
	  backgroundColor: colors.cobalt['900'],
	},
	text: {
	  fontSize: 38,
	  padding: 10,
	  color: '#fff'
	},
	button: {
	  backgroundColor: '#2D3555'
	},
	stretch: {
	  width: 200,
	  height: 200,
	  resizeMode: 'stretch',
	},
  });