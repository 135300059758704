import styled from 'styled-components/native';
import { LinkProps } from './types';

export const Container = styled.View<{ expanded: boolean }>`
	height: 100%;
	z-index: 100;
	width: ${(props) => (props.expanded ? '286px' : 'auto')};
	padding-top: 16px;
	padding-bottom: 16px;
	padding-left: 25px;
	padding-right: 25px;
	background-color: ${(props) => props.theme.components.navBar.background};
`;

export const Header = styled.View`
	padding-left: 12px;
	display: flex;
	flex-direction: row;
	margin-bottom: 16px;
	align-items: center;
	justify-content: space-between;
	position: relative;
`;

export const ToggleButton = styled.TouchableOpacity`
	position: absolute;
	right: -39px;
	background-color: ${(props) => props.theme.components.navBar.background};
	border-radius: 14px;
	z-index: 100;
`;

export const LinksList = styled.View`
	padding: 10px;
	background-color: ${(props) =>
		props.theme.components.navBar.linksList.background};
	border-radius: 12px;
`;

export const Link = styled.TouchableOpacity<LinkProps>`
	overflow: hidden;
	background-color: ${(props) =>
		props.active
			? props.theme.components.navBar.link.active.background
			: props.theme.components.navBar.link.normal.background};
	border-radius: 8px;
	margin-bottom: ${(props) => (props.lastChild ? '0' : '8px')};
`;

export const LinkContent = styled.View`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const LinkTitle = styled.Text<{ active: boolean; expanded: boolean }>`
	display: ${(props) => (props.expanded ? 'flex' : 'none')};
	margin-left: 8px;
	font-weight: 500;
	color: ${(props) =>
		props.active
			? props.theme.components.navBar.link.active.foreground
			: props.theme.components.navBar.link.normal.foreground};
`;

export const MainListContainer = styled.View`
	flex-grow: 1;
`;

export const ExtraListsContainer = styled.View``;

export const Spacer = styled.View`
	padding: 8px;
`;

export const IconWrapper = styled.View``;
