import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH = 'M23.5 19.75V23.5H8.5V19.75H6V23.5C6 24.875 7.125 26 8.5 26H23.5C24.875 26 26 24.875 26 23.5V19.75H23.5ZM22.25 14.75L20.4875 12.9875L17.25 16.2125V6H14.75V16.2125L11.5125 12.9875L9.75 14.75L16 21L22.25 14.75Z';

const ImportIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} />;
};

export default ImportIcon;
