import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH = 'M17.5693 7.05462C17.4996 7.07601 17.4309 7.10494 17.3647 7.14193L8.58094 12.0335C8.31852 12.1797 8.14341 12.4222 8.07498 12.6924C8.01258 12.8492 7.99044 13.0142 8.00528 13.1747C8.00176 13.2097 8 13.2452 8 13.2809V22.8052C8 22.8369 8.00151 22.8683 8.00428 22.8993C8.01661 23.2623 8.21839 23.614 8.56509 23.807L17.6392 28.8606C17.9784 29.0493 18.3731 29.0403 18.6846 28.8707C18.7359 28.8518 18.7865 28.8287 18.8361 28.8012L27.6873 23.8719C28.0584 23.6654 28.2551 23.2661 28.2222 22.8683L28.2227 22.8618C28.2247 22.8348 28.2257 22.8079 28.2257 22.781V13.2228C28.226 13.1891 28.2244 13.1554 28.2217 13.1219L28.2207 13.1116C28.2149 13.0525 28.2041 12.9946 28.189 12.9387L28.1875 12.934C28.1153 12.6728 27.9427 12.4396 27.6873 12.2974L18.4129 7.13237C18.1449 6.98317 17.8392 6.9643 17.5693 7.05462ZM19.1068 18.6583V26.2508L26.1291 22.34V14.7475L19.1068 18.6583ZM10.0966 22.2602L17.01 26.1104V18.7129L10.0966 14.863V22.2602ZM11.108 13.026L18.0093 16.8695L24.7984 13.0884L17.8974 9.24529L11.108 13.026Z';

const DevicesIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} fillRule='evenodd' />;
};

export default DevicesIcon;
