import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';


// TODO (jaime): Re export some icons to match our default icon logic
const Icon: React.FC<IconProps> = ({ path, size, color, style }) => {
	return (
		<Svg
			style={style}
			width={size}
			height={size}
			viewBox='0 0 32 32'
			fill='none'>
			<Path d={path} fill={color} />
		</Svg>
	);
};
Icon.defaultProps = {
	size: '32px',
	color: '#F5F6FA'
};

export default Icon;
