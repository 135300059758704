import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH = 'M6 18.3029H9.01342C9.34591 18.3029 9.65452 18.13 9.82841 17.8462L11.1565 15.6774L14.1297 23.0447C14.2826 23.4211 14.6562 23.6609 15.0622 23.6409C15.4683 23.6218 15.817 23.3476 15.9326 22.9577L18.6231 13.8544L19.8967 19.9071C19.9712 20.2615 20.2397 20.5434 20.5904 20.6341C20.941 20.7259 21.3127 20.6112 21.5515 20.338L23.2503 18.3927L26 18.3793L25.9914 16.4694L22.8098 16.4838C22.5356 16.4847 22.2748 16.6041 22.0942 16.8105L21.3719 17.6379L19.7142 9.75848C19.6244 9.33045 19.2547 9.01803 18.818 9.00083C18.3805 8.98268 17.9868 9.26453 17.8636 9.68396L14.8769 19.7886L12.2189 13.2028C12.0842 12.8675 11.7717 12.6381 11.4116 12.6076C11.0523 12.578 10.7064 12.7538 10.5182 13.0614L8.47838 16.392H6V18.3029Z';

const VitalsIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} fillRule='evenodd' />;
};

export default VitalsIcon;
