import colors from '../colors/colors';
import { Theme } from '../../../types/theme';
import { VictoryThemeDefinition } from 'victory';

const chartsTheme: VictoryThemeDefinition = {
	line: {
		colorScale: ['#01DBC5', '#94F0E5', '#D47DD4', '#B13587'],
		style: {
			data: {
				stroke: colors.cobalt['500'],
				strokeWidth: 2
			},
			labels: {
				fill: '#fff'
			}
		}
	},
	axis: {
		style: {
			axis: {
				stroke: '#516C90',
				strokeWidth: 2
			},
			grid: {
				stroke: (args) => {
					if (args.scale?.y) {
						return '#516C90';
					}
					return 'none';
				},
				strokeDasharray: '1, 2'
			},
			ticks: {
				stroke: '#516C90',
				strokeWidth: 2,
				size: 4
			},
			tickLabels: {
				fill: '#fff',
				padding: 4
			}
		}
	}
};


const theme: Theme = {
	colors: {
		primary: colors.cobalt['700'],
		secondary: colors.cyan['500'],
	},
	spacing: {
		tiny: 4,
		extraSmall: 8,
		small: 16,
		medium: 24,
		large: 32,
		extraLarge: 64
	},
	font: {
		primary: 'Inter_400Regular',
		primaryMedium: 'Inter_500Medium',
		primarySemibold: 'Inter_600SemiBold',
		colors: {
			primary: colors.grey['100'],
			secondary: colors.grey['200']
		}
	},
	screens: {
		sm: 576,
		md: 768,
		lg: 1536
	},
	components: {
		buttons: {
			primary: {
				background: colors.cyan['600'],
				color: colors.cobalt['700'],
				underlayColor: colors.purple['500']
			}
		},
		navBar: {
			background: colors.cobalt['900'],
			linksList: {
				background: colors.cobalt['700']
			},
			link: {
				normal: {
					background: colors.cobalt['700'],
					foreground: '#fff'
				},
				active: {
					background: colors.cyan['600'],
					foreground: colors.cobalt['700']
				},
				hover: {
					background: colors.cobalt['600'],
					foreground: '#fff'
				}
			}
		},
		screenHeader: {
			background: colors.cobalt['800'],
			foreground: '#fff'
		},
		screen: {
			background: colors.cobalt['800']
		},
		profileDropdown: {
			background: colors.cobalt['500'],
			foreground: '#fff',
			iconBackground: colors.purple['600'],
			iconForeground: colors.cobalt['700'],
			dividerColor: colors.cobalt['700']
		},
		connectivityLineChart: {
			background: colors.cobalt['700'],
			dataSetColors: {
				good: '#01DBC5',
				fair: '#94F0E5',
				poor: '#D47DD4',
				noData: '#B13587'
			}
		},
		segmentedControl: {
			defaultBackground: colors.cobalt['600'],
			defaultForeground: '#fff',
			activeBackground: colors.cyan['500'],
			activeForeground: colors.cobalt['700']
		},
		dataTable: {
			background: colors.cobalt['700'],
			headerBackground: colors.cobalt['600'],
			headerTextColor: colors.grey['200'],
			rowDividerColor: colors.cobalt['400'],
			rowTextColor: colors.grey['100'],
			iconBackground: colors.cobalt['700'],
			onlineDot: colors.cyan['500']
		}
	},
	charts: chartsTheme
};

export default theme;
