import React from 'react';
import { Text } from 'react-native';
import { ButtonProps } from '../types';
import * as Styled from './styled';


const PrimaryButton: React.FC<ButtonProps> = ({ size, title, onPress, style }) => {
	return (
		<Text style={style}>
			<Styled.PrimaryButtonContainer onPress={onPress}>
				<Styled.PrimaryButtonContent size={size}>{title}</Styled.PrimaryButtonContent>
			</Styled.PrimaryButtonContainer>
		</Text>
	);
};


export default PrimaryButton;
