import React, { useState } from 'react';
import differenceInMonths from 'date-fns/differenceInMonths';
import add from 'date-fns/add';
import { useTheme } from 'styled-components/native';
import { MultiLineChart } from '../multi-line-chart';
import { SegmentedControl } from '../../segmented-control';
import * as Styled from './styled';
import { Title } from '../../type/title/styled';
import { ConnectivityInfo, Domain } from './types';
import {
	extractConnectivityInfoDate,
	filterBetween,
	formatTick,
	getLast12HoursDomain,
	getLastDayDomain,
	getLastMonthDomain,
	getLastWeekDomain
} from './utils';

// TODO(carlos): Remove this function once we load data from the API
const makeConnectivityDataItems = (): ConnectivityInfo[] => {
	let current = new Date();
	const items = [];
	let i = 0;

	while (differenceInMonths(current, new Date()) < 1) {
		items.push({
			time: current.toISOString(),
			key: current.toISOString(),
			good: Math.random() * Math.sin(i) * Math.cos(i + 3) * 50 + 50,
			poor:
				Math.random() * Math.cos(i + 239) * Math.sin(i * Math.random()) * 50 +
				70,
			fair: Math.random() * Math.sin(i + 23) * 30 + 50,
			noData: Math.random() * Math.sin(i + 983) * 10 + 90
		});
		current = add(current, { minutes: 30 });
		i++;
	}

	return items;
};
const fullData = makeConnectivityDataItems();

export const ConnectivityLevelChart: React.FC = () => {
	const [domain, setDomain] = useState<Domain>(
		getLast12HoursDomain(fullData.map(extractConnectivityInfoDate))
	);
	const theme = useTheme();

	return (
		<Styled.Container>
			<Styled.TitleBar>
				<Title size='medium'>
					Cellular Devices by Connectivity Level Over Time
				</Title>
				<Styled.TitleExtra type='regular'>hours in UTC</Styled.TitleExtra>
			</Styled.TitleBar>
			<Styled.Body>
				<Styled.TimeWindowSegmentedControlContainer>
					<SegmentedControl
						initialItemId='12hours'
						items={[
							{
								id: '12hours',
								label: '12 hours',
								onPress: () => {
									setDomain(
										getLast12HoursDomain(
											fullData.map(extractConnectivityInfoDate)
										)
									);
									return true;
								}
							},
							{
								id: '1day',
								label: 'Day',
								onPress: () => {
									setDomain(
										getLastDayDomain(fullData.map(extractConnectivityInfoDate))
									);
									return true;
								}
							},
							{
								id: '1week',
								label: 'Week',
								onPress: () => {
									setDomain(
										getLastWeekDomain(fullData.map(extractConnectivityInfoDate))
									);
									return true;
								}
							},
							{
								id: '1month',
								label: 'Month',
								onPress: () => {
									setDomain(
										getLastMonthDomain(
											fullData.map(extractConnectivityInfoDate)
										)
									);
									return true;
								}
							}
						]}
					/>
				</Styled.TimeWindowSegmentedControlContainer>
				<Styled.ChartContainer>
					<MultiLineChart
						height={270}
						data={filterBetween(fullData, domain.start, domain.end)}
						getX={(item) => new Date(item.time).getTime()}
						formatTick={formatTick}
						dataAccessors={[
							{
								color:
									theme.components.connectivityLineChart.dataSetColors.good,
								accessorKey: 'good'
							},
							{
								color:
									theme.components.connectivityLineChart.dataSetColors.fair,
								accessorKey: 'fair'
							},
							{
								color:
									theme.components.connectivityLineChart.dataSetColors.poor,
								accessorKey: 'poor'
							},
							{
								color:
									theme.components.connectivityLineChart.dataSetColors.noData,
								accessorKey: 'noData'
							}
						]}
					/>
				</Styled.ChartContainer>
			</Styled.Body>
		</Styled.Container>
	);
};
