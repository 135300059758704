import React from 'react';
import * as Styled from './styled';
import { ParagraphProps } from './types';


const Paragraph: React.FC<ParagraphProps> = ({ children, size }) => {
	return (
		<Styled.Paragaraph size={size}>
			{children}
		</Styled.Paragaraph>
	);
};
Paragraph.defaultProps = {
	size: 'small'
};

export default Paragraph;
