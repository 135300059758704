import styled from 'styled-components/native';
import { TableStylesProps } from '../../components/ui/data-tables/types';

export const OnlineDot = styled.View`
	width: 9px;
	height: 9px;
	border-radius: 5px;
	background-color: #6ae9fc;
`;

export const ConnectivityCell = styled.View<TableStylesProps>`
	width: 100%;
	height: 100%;
	padding: 5px 0;
	background-color: ${(props) => props.backgroundColor};
`;

export const Cell = styled.View`
	backgroundColor: ${({ theme }) => theme.components.dataTable.background};
	height: 60px;
	alignItems: center;
	flexDirection: row;
	borderBottomColor: ${({ theme }) => theme.components.dataTable.rowDividerColor};
	borderBottomWidth: 1px;
`;

export const CellText = styled.Text<TableStylesProps>`
	color: ${({ color, theme }) => (
		color ? color : theme.components.dataTable.rowTextColor
	)};
	fontSize: ${(props) => props.fontSize ? props.fontSize : '14px'}
	fontFamily: ${({ fontFamily, theme }) => (
		fontFamily ? fontFamily : theme.font.primary
	)}
	lineHeight: 20px;
	paddingLeft: 8px;
`;

export const Container = styled.ScrollView`
	background-color: ${({ theme }) => theme.components.screen.background};
	min-height: 100%;
	padding: 23px;
`;

export const DataTableContainer = styled.View`
	padding: 17px 0;
	backgroundColor: ${({ theme }) => theme.colors.primary};
	borderRadius: 12px;
`;

export const DataTableTitleContainer = styled.View`
	paddingBottom: 17px;
	paddingLeft: 17px;
`;

export const DataTableSearchContainer = styled.View`
	padding: 17px;
`;

export const Separator = styled.View`
	height: 1px;
	backgroundColor: #284767;
	borderRadius: 5px;
`;


export const ArrowIconContainer = styled.View`
	position: absolute;
	right: 10px;
	backgroundColor: ${({ theme }) => theme.components.dataTable.iconBackground};
	height: 20px;
	width: 21px;
	borderRadius: 20px;
`;

export const Header = styled.Pressable`
	color: ${({ theme }) => theme.components.dataTable.headerBackground};
	backgroundColor: ${({ theme }) => theme.components.dataTable.headerBackground};
	padding: 5px 8px;
	height: 40px;
	fontSize: 12px;
	borderBottomColor: ${({ theme }) => theme.components.dataTable.rowDividerColor};
	borderBottomWidth: 2px;
	borderRightColor: ${({ theme }) => theme.components.dataTable.background};
	borderRightWidth: 1px;
	justify-content: center;
`;
