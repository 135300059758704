import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components/native';
import { MediaQueriesProps } from './types';


export const IsDesktop: React.FC<MediaQueriesProps> = ({ content }) => {
	const { screens } = useTheme();
	const isDesktop = useMediaQuery({ minWidth: screens.md });
	return isDesktop ? content : null;
};

export const IsTablet: React.FC<MediaQueriesProps> = ({ content }) => {
	const { screens } = useTheme();
	const isTablet = useMediaQuery({ minWidth: screens.sm, maxWidth: screens.md });
	return isTablet ? content : null;
};

export const IsMobile: React.FC<MediaQueriesProps> = ({ content }) => {
	const { screens } = useTheme();
	const isLargeDesktop = useMediaQuery({ maxWidth: screens.sm });
	return isLargeDesktop ? content : null;
};

