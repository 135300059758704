/* eslint-disable camelcase*/
import React from 'react';
import * as Styled from './styled';
import {
	useFonts,
	Inter_400Regular,
	Inter_600SemiBold
} from '@expo-google-fonts/inter';
import { TextProps } from './types';


const Text: React.FC<TextProps> = ({ children, style }) => {
	const [fontsLoaded] = useFonts({
		Inter_400Regular,
		Inter_600SemiBold
	});

	if (!fontsLoaded) {
		return null;
	}

	return (
		<Styled.Type style={[style]}>{children}</Styled.Type>
	);
};

export default Text;
