import React, { useState } from 'react';
import { Col, Row } from 'react-native-easy-grid';
import { DataTableHeadersProps } from './types';
import { Label } from '../type/label/styled';
import UpArrowIcon from '../icons/up-arrow';
import DownArrowIcon from '../icons/down-arrow';
import * as Styled from './styled';

type Order = 'asc' | 'desc';

const DataTableHeaders: React.FC<DataTableHeadersProps> = ({
	columns: Columns,
	onSort
}) => {
	const [columns, setColumns] = useState(Columns);
	const [sortOrder, setSortOrder] = useState<Order>('asc');

	return (
		<Row>
			{
				columns && columns.map((column, index) => {
					return (
						<Col style={{ flex: column.size }} key={index}>
							<Styled.Header
								onPress={() => {
									let order: Order = 'asc';
									setColumns((prevValue) => {
										return prevValue.map((c) => {
											c.displaySortIcon = c.name === column.name;
											if (c.displaySortIcon) {
												setSortOrder((prevValue) => {
													order = prevValue === 'asc' ? 'desc' : 'asc';
													return order;
												});
											}
											return c;
										});
									});
									onSort({ column: 'deviceName', order });
								}}>
								<Label type='bold'>{column.name}</Label>
								{
									column.displaySortIcon
										? (
											<Styled.ArrowIconContainer>
												{
													sortOrder === 'asc'
														? <UpArrowIcon style={{ width: 14, margin: 'auto' }} />
														: <DownArrowIcon style={{ width: 14, margin: 'auto' }} />
												}
											</Styled.ArrowIconContainer>
										)
										: null
								}
							</Styled.Header>
						</Col>
					);
				})
			}
		</Row>
	);
};

export default DataTableHeaders;
