import styled from 'styled-components/native';
import { TableStylesProps } from '../types';

export const OnlineDot = styled.View`
	width: 9px;
	height: 9px;
	border-radius: 5px;
	background-color: #6ae9fc;
`;

export const ConnectivityCell = styled.View<TableStylesProps>`
	width: 100%;
	height: 100%;
	padding: 5px 0;
	background-color: ${(props) => props.backgroundColor};
`;

export const Cell = styled.View`
	backgroundColor: ${({ theme }) => theme.components.dataTable.background};
	height: 60px;
	alignItems: center;
	flexDirection: row;
	borderBottomColor: ${({ theme }) => theme.components.dataTable.rowDividerColor};
	borderBottomWidth: 1px;
`;

export const CellText = styled.Text<TableStylesProps>`
	color: ${({ color, theme }) => (
		color ? color : theme.components.dataTable.rowTextColor
	)};
	fontSize: ${(props) => props.fontSize ? props.fontSize : '14px'}
	fontFamily: ${({ fontFamily, theme }) => (
		fontFamily ? fontFamily : theme.font.primary
	)}
	lineHeight: 20px;
	paddingLeft: 8px;
`;
