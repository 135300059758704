import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH = 'M23.6667 19.75V23.5H7.66667V19.75H5V23.5C5 24.875 6.2 26 7.66667 26H23.6667C25.1333 26 26.3333 24.875 26.3333 23.5V19.75H23.6667ZM9 12.25L10.88 14.0125L14.3333 10.7875V21H17V10.7875L20.4533 14.0125L22.3333 12.25L15.6667 6L9 12.25Z';

const ExportIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} />;
};

export default ExportIcon;
