import styled from 'styled-components/native';

export const Container = styled.View`
	min-height: 100%;
	display: flex;
	flex-direction: row;
`;

export const Content = styled.View`
	min-height: 100%;
	background-color: ${(props) => props.theme.components.screen.background};
	height: 300px;
	flex-grow: 1;
`;
