import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH =
	'M16,24.727L14.462,23.189L20.549,17.091L7.273,17.091L7.273,14.909L20.549,14.909L14.462,8.811L16,7.273L24.727,16L16,24.727Z';

export const ArrowForwardIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} />;
};
