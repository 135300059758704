import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH = 'M26 16L24.2375 14.2375L17.25 21.2125L17.25 6L14.75 6L14.75 21.2125L7.7625 14.2375L6 16L16 26L26 16Z';

const DownArrowIcon: React.FC<IconProps> = ({ size, color, style }) => {
	return <Icon style={style} path={ICON_PATH} size={size} color={color} />;
};

export default DownArrowIcon;
