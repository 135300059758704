import React from 'react';
import * as Styled from './styled';
import { LinksListProps } from './types';

export const LinksList: React.FC<LinksListProps> = ({
	links,
	onLinkPress,
	expanded
}) => (
	<Styled.LinksList>
		{links.map((link, index) => {
			const active = 'active' in link && link.active;

			return (
				<Styled.Link
					key={link.key}
					onPress={() => onLinkPress(link)}
					lastChild={index === links.length - 1}
					active={active}
				>
					<Styled.LinkContent>
						<Styled.IconWrapper>{link.icon(link)}</Styled.IconWrapper>
						<Styled.LinkTitle active={active} expanded={expanded}>
							{link.title}
						</Styled.LinkTitle>
					</Styled.LinkContent>
				</Styled.Link>
			);
		})}
	</Styled.LinksList>
);
