import styled from 'styled-components/native';


export const ArrowIconContainer = styled.View`
	position: absolute;
	right: 10px;
	backgroundColor: ${({ theme }) => theme.components.dataTable.iconBackground};
	height: 20px;
	width: 21px;
	borderRadius: 20px;
`;

export const Header = styled.Pressable`
	color: ${({ theme }) => theme.components.dataTable.headerBackground};
	backgroundColor: ${({ theme }) => theme.components.dataTable.headerBackground};
	padding: 5px 8px;
	height: 40px;
	fontSize: 12px;
	borderBottomColor: ${({ theme }) => theme.components.dataTable.rowDividerColor};
	borderBottomWidth: 2px;
	borderRightColor: ${({ theme }) => theme.components.dataTable.background};
	borderRightWidth: 1px;
	justify-content: center;
`;
