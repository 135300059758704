import React from 'react';
import { Platform, View } from 'react-native';
import { ArrowBackIcon, ArrowForwardIcon, ParticleMarkIcon } from '../../icons';
import { LinksList } from './links-list';
import * as Styled from './styled';
import { NavBarProps } from './types';

export const TEST_IDS = {
	toggleButton: 'nav-bar__toggle-button'
};

export const NavBar: React.FC<NavBarProps> = ({
	nav,
	extra,
	onTogglePress,
	expanded
}) => (
	<Styled.Container expanded={expanded}>
		<Styled.Header>
			<ParticleMarkIcon size='32px' />
			{Platform.OS === 'web' && (
				<Styled.ToggleButton
					onPress={onTogglePress}
					testID={TEST_IDS.toggleButton}
				>
					{expanded ? (
						<ArrowBackIcon size='28px' />
					) : (
						<ArrowForwardIcon size='28px' />
					)}
				</Styled.ToggleButton>
			)}
		</Styled.Header>
		<Styled.MainListContainer>
			<LinksList
				links={nav.links}
				onLinkPress={nav.onLinkPress}
				expanded={expanded}
			/>
		</Styled.MainListContainer>
		<Styled.ExtraListsContainer>
			{extra.map((linksList, index) => (
				<View key={index}>
					<Styled.Spacer />
					<LinksList
						links={linksList.links}
						onLinkPress={linksList.onLinkPress}
						expanded={expanded}
					/>
				</View>
			))}
		</Styled.ExtraListsContainer>
	</Styled.Container>
);
