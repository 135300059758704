import React from 'react';
import styled from 'styled-components/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useTheme } from 'styled-components/native';
import { ScreenHeader } from '../../components';
import { View } from 'react-native';
import Title from '../../components/ui/type/title/title';
import Paragraph from '../../components/ui/type/paragraph/paragraph';
import Label from '../../components/ui/type/label/label';
import PrimaryButton from '../../components/ui/buttons/primary/primary-button';
import MonitorIcon from '../../components/ui/icons/monitor';
import AddIcon from '../../components/ui/icons/add';
import DeleteIcon from '../../components/ui/icons/delete';
import DevicesIcon from '../../components/ui/icons/devices';
import DocsIcon from '../../components/ui/icons/docs';
import DownArrowIcon from '../../components/ui/icons/down-arrow';
import DownloadIcon from '../../components/ui/icons/download';
import EditIcon from '../../components/ui/icons/edit';
import ExportIcon from '../../components/ui/icons/export';
import FilterIcon from '../../components/ui/icons/filter';
import ImportIcon from '../../components/ui/icons/import';
import IntegrationsIcon from '../../components/ui/icons/integrations';
import LocationIcon from '../../components/ui/icons/location';
import LogoutIcon from '../../components/ui/icons/logout';
import MainNavigationIcon from '../../components/ui/icons/main-navigation';
import MoreIcon from '../../components/ui/icons/more';
import NotificationsIcon from '../../components/ui/icons/notifications';
import ProfileIcon from '../../components/ui/icons/profile';
import SearchIcon from '../../components/ui/icons/search';
import SettingsIcon from '../../components/ui/icons/settings';
import SupportIcon from '../../components/ui/icons/support';
import UpArrowIcon from '../../components/ui/icons/up-arrow';
import VitalsIcon from '../../components/ui/icons/vitals';


const Stack = createStackNavigator();

const Container = styled.View`
	backgroundColor: ${({ theme }) => theme.components.screen.background};
	min-height: 100%;
	padding: 10px;
`;

const Divider: React.FC = () => {
	return (
		<View
			style={{
				borderBottomColor: 'white',
				borderBottomWidth: 1,
				marginTop: 10
			}}
		/>
	);
};

const TypesContainer = () => (
	<View>
		<Title size='medium'>Text:</Title>
		<Title size='large'>Large Title</Title>
		<Title size='medium'>Medium Title</Title>
		<Title size='small'>Small Title</Title>
		<Paragraph size='regular'>Regular Paragraph</Paragraph>
		<Paragraph size='small'>Small Paragraph</Paragraph>
		<Label type='regular'>Regular Label</Label>
		<Label type='bold'>Bold Label</Label>
		<Divider />
	</View>
);

const ColorsContainer = () => {
	const { colors } = useTheme();

	return (
		<View>
			<Title size='medium'>Colors:</Title>
			<View style={{ flexDirection: 'row' }}>
				{
					Object.entries(colors).map((el, index) => {
						return (
							<View key={index}>
								<Label type='regular'>{el[0]}</Label>
								<View style={{
									height: 50,
									width: 50,
									margin: 5,
									backgroundColor: el[1],
									borderRadius: 10
								}}></View>
							</View>
						);
					})
				}
			</View>
			<Divider />
		</View>
	);
};

const IconsContainer = () => (
	<View>
		<Title size='medium'>Icons:</Title>
		<View>
			<View style={{ flexDirection: 'row' }}>
				<MonitorIcon />
				<AddIcon />
				<DeleteIcon />
				<DevicesIcon />
				<DocsIcon />
				<DownArrowIcon />
				<EditIcon />
				<DownloadIcon />
			</View>
			<View style={{ flexDirection: 'row' }}>
				<ExportIcon />
				<FilterIcon />
				<ImportIcon />
				<IntegrationsIcon />
				<LocationIcon />
				<LogoutIcon />
				<MoreIcon />
				<MainNavigationIcon />
			</View>
			<View style={{ flexDirection: 'row' }}>
				<NotificationsIcon />
				<ProfileIcon />
				<SearchIcon />
				<SettingsIcon />
				<SupportIcon />
				<UpArrowIcon />
				<VitalsIcon />
			</View>
		</View>
		<Divider />
	</View>
);

const ButtonsContainer = () => (
	<View>
		<Title size='medium'>Buttons:</Title>
		<PrimaryButton style={{ margin: '10px' }} size='medium' title='Medium Button' />
		<PrimaryButton style={{ margin: '10px' }} size='large' title='Large Button' />
		<Divider />
	</View>
);

const StyleGuideContainer = () => {
	return (
		<Container>
			<TypesContainer />
			<ColorsContainer />
			<IconsContainer />
			<ButtonsContainer />
		</Container>
	);
};

export const StyleGuide: React.FC = () => (
	<Stack.Navigator
		screenOptions={{
			header: () => (
				<ScreenHeader
					title='Style Guide'
				/>
			)
		}}
	>
		<Stack.Screen name="Connectivity" component={StyleGuideContainer} />
	</Stack.Navigator>
);
