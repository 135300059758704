import React from 'react';
import * as Styled from './styled';
import { LabelProps } from './types';

const Label: React.FC<LabelProps> = ({ children, type, style }) => {
	return (
		<Styled.Label type={type} style={style}>
			{children}
		</Styled.Label>
	);
};
Label.defaultProps = {
	type: 'regular'
};

export default Label;
