import { gql, QueryResult, useQuery } from '@apollo/client';
import { Connectivity } from '../components/ui/data-tables/connectivity-level/types';


export const GET_DEVICE_QUERY = gql`
	query GetFleetConnectivity ($orderBy:DeviceConnectivitySort) {
		fleetConnectivity(orderBy: $orderBy) {
			connectivity {
				deviceName
				deviceId
				deviceSerialNumber
				lastHeard
				connectivityLevel
				connectivityPercent
				connectivityType
				operator
				cellAreaLocation
				groups
				product
				firmwareVersion
				deviceOSVersion
			}
		}
	}
`;

export interface OrderBy {
	deviceName: 'asc' | 'desc';
}

interface FleetConnectivity {
	connectivity: Connectivity[];
}

interface GetDeviceData {
	fleetConnectivity?: FleetConnectivity;
}

export interface UseDeviceQuery {
	orderBy: OrderBy
}

export const useDeviceQuery = ({ orderBy }: UseDeviceQuery): QueryResult<GetDeviceData> => {
	return useQuery<GetDeviceData>(GET_DEVICE_QUERY, {
		variables: {
			orderBy
		}
	});
};
