import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH = 'M12 20.2222H20V22.2222H12V20.2222ZM12 16.2222H20V18.2222H12V16.2222ZM18 6.22217H10C8.9 6.22217 8 7.12217 8 8.22217V24.2222C8 25.3222 8.89 26.2222 9.99 26.2222H22C23.1 26.2222 24 25.3222 24 24.2222V12.2222L18 6.22217ZM22 24.2222H10V8.22217H17V13.2222H22V24.2222Z';

const DocsIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} />;
};

export default DocsIcon;
