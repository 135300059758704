import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH = 'M6 22.3333H26V20.1111H6V22.3333ZM6 16.7778H26V14.5556H6V16.7778ZM6 9V11.2222H26V9H6Z';

const MainNavigationIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} />;
};

export default MainNavigationIcon;
