import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH = 'M18.4635 12.8924L19.4848 11.5383C19.7514 11.6495 20.0616 11.7156 20.3725 11.6713C21.3714 11.5602 22.1036 10.65 21.9931 9.65117C21.8826 8.65234 20.9718 7.92007 19.973 8.03056C18.9741 8.14168 18.2419 9.05187 18.3524 10.0507C18.3967 10.4059 18.5297 10.7168 18.7294 10.9827L17.7081 12.3368C17.9747 12.4922 18.2188 12.692 18.4629 12.8918L18.4635 12.8924Z M23.7018 17.8865C23.2136 17.9533 22.7697 18.1974 22.4807 18.5526L19.5729 17.2429C19.4842 17.5313 19.3506 17.8197 19.1958 18.0863L22.1255 19.4179C22.103 19.5733 22.0812 19.7506 22.103 19.9285C22.2141 20.9274 23.1243 21.6596 24.1232 21.5491C25.122 21.4386 25.8543 20.5278 25.7438 19.529C25.6108 18.4858 24.7006 17.7754 23.7018 17.8865Z M17.5087 23.9463C17.4201 23.1697 16.8426 22.548 16.1328 22.3707L16.0885 20.0846C16.066 20.0846 16.066 20.0846 16.0442 20.0846C15.7558 20.1071 15.4674 20.1071 15.1783 20.0846L15.2008 22.3931C14.3574 22.6372 13.7581 23.4588 13.8692 24.3683C13.9803 25.3672 14.8905 26.0994 15.8894 25.9889C16.8882 25.856 17.6205 24.9676 17.51 23.947L17.5087 23.9463Z M9.34089 17.6205C8.96383 17.0656 8.31959 16.7328 7.63164 16.8215C6.63281 16.9326 5.90054 17.8428 6.01103 18.8416C6.12153 19.8405 7.03234 20.5727 8.03117 20.4622C9.00753 20.3511 9.74043 19.4859 9.65178 18.487L11.8049 17.7323C11.6719 17.4657 11.5608 17.1773 11.494 16.8664L9.34089 17.6212V17.6205Z M12.1593 9.62993L13.4247 12.3599C13.6912 12.2044 13.9572 12.0715 14.268 11.9603L12.9808 9.25225C13.4471 8.87519 13.7131 8.27589 13.6469 7.63164C13.5358 6.63281 12.6256 5.90054 11.6268 6.01103C10.6279 6.12153 9.89565 7.03234 10.0062 8.03117C10.1173 9.03001 11.0275 9.76228 12.0263 9.65178C12.0706 9.65178 12.1149 9.65178 12.1593 9.62931V9.62993Z M18.7297 15.9333C18.7297 17.6738 17.3188 19.0853 15.5777 19.0853C13.8366 19.0853 12.4258 17.6744 12.4258 15.9333C12.4258 14.1928 13.8366 12.7814 15.5777 12.7814C17.3188 12.7814 18.7297 14.1922 18.7297 15.9333Z';

const IntegrationsIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} />;
};

export default IntegrationsIcon;
