import React from 'react';
import { useTheme } from 'styled-components/native';
import { ProfileIcon } from '../../../icons';
import * as Styled from './styled';

// TODO(carlos): Remove this component once all the icons support backround styling.
export const ProfileIconWithBackground: React.FC = () => {
	const theme = useTheme();
	return (
		<Styled.IconBackround>
			<ProfileIcon
				size='24px'
				color={theme.components.profileDropdown.iconForeground}
			/>
		</Styled.IconBackround>
	);
};
