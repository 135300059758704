import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH = 'M16 19.8182L19.6364 16.1818H16.9091V8H15.0909V16.1818H12.3636L16 19.8182ZM24.1818 8H18.7273V9.80909H24.1818V22.5636H7.81818V9.80909H13.2727V8H7.81818C6.81818 8 6 8.81818 6 9.81818V22.5455C6 23.5455 6.81818 24.3636 7.81818 24.3636H24.1818C25.1818 24.3636 26 23.5455 26 22.5455V9.81818C26 8.81818 25.1818 8 24.1818 8ZM16 19.8182L19.6364 16.1818H16.9091V8H15.0909V16.1818H12.3636L16 19.8182ZM24.1818 8H18.7273V9.80909H24.1818V22.5636H7.81818V9.80909H13.2727V8H7.81818C6.81818 8 6 8.81818 6 9.81818V22.5455C6 23.5455 6.81818 24.3636 7.81818 24.3636H24.1818C25.1818 24.3636 26 23.5455 26 22.5455V9.81818C26 8.81818 25.1818 8 24.1818 8Z';

const DownloadIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} />;
};

export default DownloadIcon;
