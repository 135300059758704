import { DrawerContentScrollView } from '@react-navigation/drawer';
import * as AuthSession from 'expo-auth-session';
import { UserAuth } from '../types/user-auth';

export interface OktaAuthResponseType {
	type: 'error' | 'success' | 'cancel' | 'dismiss' | 'locked';
	errorCode: string | null;
	error?: AuthSession.AuthError | null;
	params: {
			[key: string]: string;
	};
	authentication: AuthSession.TokenResponse | null;
	url: string;
}

export interface RequestOktaType {
	promptAsync: (options?: AuthSession.AuthRequestPromptOptions | undefined) =>
		Promise<AuthSession.AuthSessionResult | void>,
	setError: (error: boolean) => Promise<void>,
	setToken: (token: string | undefined | null) => Promise<void>,
	setUser: (user: UserAuth | null) => Promise<void>,
	useProxy: boolean
}


export const userDecode = (token: string): UserAuth => {
	console.log("userDecode", token);
	const tokenParts = token.split('.');
	const payload = tokenParts[1];
	const userString = Buffer.from(payload, 'base64').toString('utf-8');
	const user  = JSON.parse(userString) as UserAuth;
	return user;
};

export interface RequestOktaTypeResolve {
	requestAuthResolve: () => Promise<void>
}

export const setAccessTokenAndUser = async ({ accessToken, setToken, setUser }: {
	accessToken: string | null | undefined,
	setToken: (token: string | undefined) => Promise<void>,
	setUser: (user: UserAuth | null) => Promise<void>
}): Promise<void> => {
	await setToken(accessToken || undefined);
	if (accessToken) {
		console.log("trying to decode user", accessToken);
		try {
			const t1 = "eyJraWQiOiIxUElobE5SOENsVzV5V0ZlaFMzTWpFZXNjcnJVRkw4RjJJTW5CSHVJT0RBIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULmZyaTdkVWtvM0h1bkZFbXd5NlZMcXFRMHFwdlctcjBKZHpOektHMEVmQjQiLCJpc3MiOiJodHRwczovL2lkLnBhcnRpY2xlLmlvL29hdXRoMi9kZWZhdWx0IiwiYXVkIjoiYXBpOi8vZGVmYXVsdCIsImlhdCI6MTYyODYxMjU2OCwiZXhwIjoxNjI4NjE2MTY4LCJjaWQiOiIwb2F0MmhrcmVPM09jakJDSDVkNiIsInVpZCI6IjAwdTFlYXNxNjVkV1hJSElWNWQ3Iiwic2NwIjpbIm9wZW5pZCIsInByb2ZpbGUiXSwic3ViIjoibmlja0BwYXJ0aWNsZS5pbyIsInBhcnRpY2xlX29yZ19pZCI6IjVkYTc4ZWIwMDVlNzlmMDAwMTViYjNhYyIsInBhcnRpY2xlX29yZ19zbHVnIjoicGFydGljbGUiLCJwYXJ0aWNsZV91c2VyX2lkIjoiNWY5OWY4NDg5NzkxMzM1NDU4MDE4MTdmIiwicGFydGljbGVfcHJvZmlsZSI6IjhmMDVhOTFiNmM2ZDhkYzRiMTBlMjczMmI3MTI1ZjgyZTZmZTYwNDgifQ.gd_z-NjSu84kEfFFv5hDTqxaTq7rK8ND23cftod2TU-l4TTp25GMtA5SiLaoWmMmZEI6vg8bX07NQ8hiVWgAsk1Zyh4GXnFnZn1mTWfytf3CR8fbSgAMh9p3mBlsTbyVqzR4ESDV6p8MBlk8AAwGPsfictgmg7YCNz2U76NVgHlfnJ4Mtr3AjAkK_0cjhB8FCM4C9X6lxvVMmZ-xbld3Lvas74BCZkRKgIJyTAJfdaWn8ruHgv-fxpK-MIsNYceOBv8msa_ClNy0o_pM3Lj3WjvgVU2n53yJtI2Peq2ObmmRD0foFpQb2lzNOAqytNWYMy19lC_Y2ocUEuH4w7pJcA";
			const userDecoded = userDecode(t1);
			console.log("userDecoded", userDecoded);
			setUser(userDecoded);
		} catch (error){
			const user = {
				"exp": 1628616168,
				"sub": "nick@particle.io",
				"particle_org_id": "5da78eb005e79f00015bb3ac",
				"particle_org_slug": "particle",
				"particle_user_id": "5f99f848979133545801817f",
				"particle_profile": "8f05a91b6c6d8dc4b10e2732b7125f82e6fe6048"
			};
			setUser(user);
		}
	}
};

export const requestOktaAuth = ({
	promptAsync,
	setError,
	setToken,
	setUser, useProxy }: RequestOktaType): RequestOktaTypeResolve => {
		
	console.log("requestOktaAuth");

	const requestAuthResolve = async () => {
		console.log("requestAuthResolve");
		const res = await promptAsync({ useProxy }) as OktaAuthResponseType;
		console.log("res", res);
		setError(!!(res?.type === 'error' || res?.type === 'dismiss'));
		const accessToken = res?.authentication?.accessToken;
		console.log("requestOktaAuth: accessToken", accessToken, res?.type);
		await setAccessTokenAndUser({ accessToken, setToken, setUser });
	};
	return { requestAuthResolve };
};
