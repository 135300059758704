import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH = 'M9.702 8.5H22.202L15.9395 16.375L9.702 8.5ZM6.2645 8.0125C8.7895 11.25 13.452 17.25 13.452 17.25V24.75C13.452 25.4375 14.0145 26 14.702 26H17.202C17.8895 26 18.452 25.4375 18.452 24.75V17.25C18.452 17.25 23.102 11.25 25.627 8.0125C26.2645 7.1875 25.677 6 24.6395 6H7.252C6.2145 6 5.627 7.1875 6.2645 8.0125Z';

const FilterIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} />;
};

export default FilterIcon;
