import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH = 'M20.2222 12.6667V23.7778H11.3333V12.6667H20.2222ZM18.5556 6H13L11.8889 7.11111H8V9.33333H23.5556V7.11111H19.6667L18.5556 6ZM22.4444 10.4444H9.11111V23.7778C9.11111 25 10.1111 26 11.3333 26H20.2222C21.4444 26 22.4444 25 22.4444 23.7778V10.4444Z';

const DeleteIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} />;
};

export default DeleteIcon;
