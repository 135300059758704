import { gql, useQuery } from '@apollo/client';
import { User } from '../types/user';

interface UserProfile {
	user: User
}

export const GET_USER_QUERY = gql`
	query getUser {
		user {
			username
			firstName
			lastName
		}
	}
`;

export default function useProfile(): User {
	const { loading, error, data } = useQuery<UserProfile>(GET_USER_QUERY);

	if (loading || error || !data){
		return { username: '', firstName: '', lastName: '' };
	}

	return data.user;
}

