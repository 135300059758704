import React from 'react';
import * as Styled from './styled';
import { IsDesktop } from '../../../components/ui/media-queries/media-queries';
import { ConnectivityLevelDataTable } from '../../../components/ui';
import { Title } from '../../../components/ui';
import { ConnectivityLevelChart, Spacer } from '../../../components/ui';


const DataTableContainer: React.FC = ({ children: table }) => (
	<Styled.DataTableContainer>
		<Styled.DataTableTitleContainer>
			<Title size='medium'>Cellular Devices by Connectivity Level</Title>
		</Styled.DataTableTitleContainer>
		<Styled.Separator />
		<Styled.DataTableSearchContainer>
			<Title size='small'>Search & Filters</Title>
		</Styled.DataTableSearchContainer>
		{table}
	</Styled.DataTableContainer>
);

export const ConnectivityScreen: React.FC = () => {
	return (
		<Styled.Container>
			<ConnectivityLevelChart />
			<Spacer size='medium' direction='vertical' />
			<IsDesktop
				content={
					<DataTableContainer>
						<ConnectivityLevelDataTable />
					</DataTableContainer>
				}
			/>
		</Styled.Container>
	);
};
