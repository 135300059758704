import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH = 'M21.5556 11.5556L19.9889 13.1222L21.7444 14.8889H12.6667V17.1111H21.7444L19.9889 18.8667L21.5556 20.4444L26 16L21.5556 11.5556ZM8.22222 8.22222H16V6H8.22222C7 6 6 7 6 8.22222V23.7778C6 25 7 26 8.22222 26H16V23.7778H8.22222V8.22222Z';

const LogoutIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} />;
};

export default LogoutIcon;
