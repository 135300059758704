import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH = 'M16 6C12.13 6 9 9.13 9 13C9 18.25 16 26 16 26C16 26 23 18.25 23 13C23 9.13 19.87 6 16 6ZM11 13C11 10.24 13.24 8 16 8C18.76 8 21 10.24 21 13C21 15.88 18.12 20.19 16 22.88C13.92 20.21 11 15.85 11 13Z M16 15.5C17.3807 15.5 18.5 14.3807 18.5 13C18.5 11.6193 17.3807 10.5 16 10.5C14.6193 10.5 13.5 11.6193 13.5 13C13.5 14.3807 14.6193 15.5 16 15.5Z';

const LocationIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} />;
};

export default LocationIcon;
