import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH = 'M6 21.8333V26H10.1667L22.4556 13.7111L18.2889 9.54444L6 21.8333ZM9.24444 23.7778H8.22222V22.7556L18.2889 12.6889L19.3111 13.7111L9.24444 23.7778ZM25.6778 8.92222L23.0778 6.32222C22.8556 6.1 22.5778 6 22.2889 6C22 6 21.7222 6.11111 21.5111 6.32222L19.4778 8.35556L23.6444 12.5222L25.6778 10.4889C26.1111 10.0556 26.1111 9.35556 25.6778 8.92222Z';

const EditIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} />;
};

export default EditIcon;
