import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components/native';
import themes from './';


const Theme: React.FC = ({ children }) => {
	const [selectedTheme] = useState(themes.dark);

	return (
		<ThemeProvider theme={selectedTheme}>
			{children}
		</ThemeProvider>
	);
};

export default Theme;
