/* istanbul ignore file */
import React from 'react';
import '@expo/match-media';
import { Platform } from 'react-native';
import { registerRootComponent } from 'expo';
import { ApolloProvider } from '@apollo/client';
import createGlobalStyles from './components/ui/global-styles';
import { client } from './lib/graphql-client';
import Theme from './components/ui/themes/theme';
// eslint-disable-next-line camelcase
import { MainNavigator } from './components/navigation/main-navigator';
import { NavigationContainer } from '@react-navigation/native';
import AuthProvider from './context/auth-context';

const GlobalStyles = createGlobalStyles(Platform.OS);

const linking = {
	prefixes: ['particle://'],
	config: {
		screens: {
			Home: '/',
			Vitals: 'vitals',
			Connectivity: 'connectivity'
		}
	}
};

const AuthScreenSelector: React.FC = () => {
	// TODO: Get the "authenticated" value by checking the access token
	// exposed by https://github.com/particle-iot-inc/volta/blob/feature/ch81470/auth-flows-for-storm/packages/storm-client/src/hooks/use-auth.ts
	return (
		<NavigationContainer
			linking={linking}
			onStateChange={(state) => console.log(state)}
		>
			<MainNavigator />
		</NavigationContainer>
	);
};

const App: React.FC = () => {
	return (
		<ApolloProvider client={client}>
			<AuthProvider>
				<GlobalStyles />
				<Theme>
					<AuthScreenSelector />
				</Theme>
			</AuthProvider>
		</ApolloProvider>
	);
};

export default App;

// TODO (mirande): this prevent testing as it errors with:
// 'TypeError: Cannot read property 'match' of null'
// at getDevServer (node_modules/react-native/Libraries/Core/Devtools/getDevServer.js:30:19)
registerRootComponent(App);
