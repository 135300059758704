import React from 'react';
import * as Styled from './styled';
import Title from '../../type/title/title';
import { ProfileDropdown } from './profile-dropdown';
import { PrimaryButton } from '../../buttons';
import useAuth from '../../../../hooks/use-auth';
import { NotificationsButton } from './notifications-button';

// TODO(carlos): Remove this component once the auth flow is fully functional
const ProfileDropdownWrapper = () => {
	const { requestAuth, user } = useAuth();

	if (!user) {
		return <PrimaryButton title='Login' size='medium' onPress={requestAuth} />;
	}
	return <ProfileDropdown />;
};

interface ScreenHeaderProps {
	title: string;
}

export const ScreenHeader: React.FC<ScreenHeaderProps> = ({ title }) => {
	return (
		<Styled.Container>
			<Styled.Leading>
				<Title size='large'>{title}</Title>
			</Styled.Leading>
			<Styled.Trailing>
				<NotificationsButton />
				<ProfileDropdownWrapper />
			</Styled.Trailing>
		</Styled.Container>
	);
};
