import React, { useState } from 'react';
import styled from 'styled-components/native';
import { createStackNavigator } from '@react-navigation/stack';
import { ScreenHeader } from '../../components';

import { gql, useQuery } from '@apollo/client';
import { StyleSheet, Dimensions, Text, Platform, Image, View } from 'react-native';

import { DeviceScreen } from './device-screen';

const supportEmbeddedMapView = Platform.select({ web: false, default: true });

const MAPBOX_TOKEN = 'pk.eyJ1IjoibXJsYW1iY2hvcCIsImEiOiJja25zb2J3MHEwNTJuMnVudmhkMnhsZGt0In0.xB81n-SMQbvb6oskSrNVNA'; // nicks personal token

var MapNativeComponent = undefined;
var MapBoxWebComponent = undefined;
var SplitPaneComponent = undefined;

if (supportEmbeddedMapView) {
  MapNativeComponent = require('react-native-maps');
} else {
  MapBoxWebComponent = require('react-map-gl');
  SplitPaneComponent = require('react-split-pane');
}

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const pinStyle = {
  fill: '#906DEC',
  stroke: 'none'
};

function Pin(props) {
  const {size = 20} = props;

  const stylized = {...pinStyle, fill: props.fill};

  return (
    <svg height={size} viewBox="0 0 24 24" style={stylized}>
      <path d={ICON} />
    </svg>
  );
}

function mungeData(data, devicesSelected) {

  if( data && data.locationByProductIDs ) {
    //console.log("locationByProductIDs devices", data.locationByProductIDs.length );

    data.locationByProductIDs.map((location, index) => {
      //console.log("location:", location.id, "coordinates", location.geometry.coordinates.length);
    });
  }

  //console.log("1", data.location.geometry.coordinates[0]);

  //const coords = { latitude: data.location.geometry.coordinates[0].lat, longitude: data.location.geometry.coordinates[0].lon };

  // data.locationByProductIDs.map((location, index) => {
  //   console.log("id", location.id, "", location.geometry.coordinates[0]);
  // });

  var locations = []

  if( data && data.locationByProductIDs && data.locationByProductIDs.length ) {
   locations = data.locationByProductIDs; 
  }

  function getLocationSubset(locations, deviceName) {
     //console.log("getLocationSubset", locations, deviceName);

    //find the location for 9205-Tracker-SanFrancisco-1 or 9205-Tracker-SanFrancisco-3
    //const singleDevice = locations.find(location => location.name > deviceName);

    var singleDevice;

    for( let i = 0; i < locations.length; i++ ) {

      //console.log("l", locations[i])

      if( locations[i].name == deviceName) {
        //console.log("ok")
        singleDevice = locations[i];
      }
    }

    //console.log("getLocationSubset found:", singleDevice);

    //last 30 items
    const last30N = singleDevice.geometry.coordinates.length - 30;
    //singleDevice.geometry.coordinates.splice(last30N);

    //first 30 items
    //singleDevice.geometry.coordinates = singleDevice.geometry.coordinates.slice(0,30);

    var getLocationSubsetRet = { ...singleDevice, geometry: {coordinates: singleDevice.geometry.coordinates.slice(last30N)} };

    //console.log("getLocationSubsetRet", getLocationSubsetRet );

    return getLocationSubsetRet;
  }

  var coordsSet1 = []
  var coordsSet2 = []

  for( let i = 0; i < devicesSelected.length; i++ ) {

    const d = getLocationSubset(locations, devicesSelected[i]);
    
    if( i == 0 ) {
      coordsSet1 = d.geometry.coordinates;
    }

    if( i == 1 ) {
      coordsSet2 = d.geometry.coordinates;
    }
  }

  return {coordsSet1,coordsSet2};
}


function LoadMarkersMapBox(props) {

  console.log("LoadMarkersMapBox", props.devicesSelected);

  const { loading, error, data } = useQuery(DEVICE_LOCATIONS, {
    pollInterval: 2500,
    fetchPolicy: "cache-and-network",
	  context: { clientName: 'hack' }
  });

  if (loading) return <React.Fragment />;

  if (error) return <React.Fragment />;

  const {coordsSet1, coordsSet2} = mungeData(data, props.devicesSelected);

  return (
    <React.Fragment>
      {coordsSet1.length && coordsSet1.map((coordinate, index) => (
        <MapBoxWebComponent.Marker key={coordinate.timeStamp} latitude={coordinate.lat} longitude={coordinate.lon} offsetLeft={-20} offsetTop={-10}>    
          <Pin size={20} fill={'#906DEC'}/>
        </MapBoxWebComponent.Marker>
      ))}

      {coordsSet2.length && coordsSet2.map((coordinate, index) => (
        <MapBoxWebComponent.Marker key={coordinate.timeStamp} latitude={coordinate.lat} longitude={coordinate.lon} offsetLeft={-20} offsetTop={-10}>    
          <Pin size={20} fill={'blue'}/>
        </MapBoxWebComponent.Marker>
      ))}

    </React.Fragment>
  );
}


function MapBoxWeb(props) {

  const [viewport, setViewport] = React.useState({
    latitude: 37.8,
    longitude: -122.4,
    zoom: 14,
    bearing: 0,
    pitch: 0
  });

  const geolocateStyle = {
    top: 0,
    left: 0,
    margin: 10
  };
  const positionOptions = {enableHighAccuracy: true};

  return (
    <MapBoxWebComponent.default
      {...viewport}
      width="100vw"
      height="100vh"
      mapStyle="mapbox://styles/mapbox/streets-v11"
      onViewportChange={setViewport}
      mapboxApiAccessToken={MAPBOX_TOKEN}>

      <LoadMarkersMapBox devicesSelected={props.devicesSelected}/>

      <MapBoxWebComponent.GeolocateControl
        style={geolocateStyle}
        positionOptions={positionOptions}
        trackUserLocation
      />

    </MapBoxWebComponent.default>
  );
}

//time to go back
//120 mins == 1000 * 60 * 120
//5 mins == 1000 * 60 * 5
//3 days ==  1000 * 60 * 60 * 24 * 3

const DEVICE_LOCATIONS = gql`
  query GetDeviceLocations {
    locationByProductIDs(productIDs:[15131], date1: "0", date2: "259200000") {
        id
        name
        geometry {
          type
          coordinates {
            lat
            lon
            hdop
            timeStamp
          }
        }
        gps_lock
        online
      }
    }
`;


const mapStyle = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#181818"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1b1b1b"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#2c2c2c"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8a8a8a"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#373737"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#4e4e4e"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#3d3d3d"
      }
    ]
  }
]

function LoadMarkers(props) {

  console.log("LoadMarkers", props);

  const { loading, error, data } = useQuery(DEVICE_LOCATIONS, {
    pollInterval: 2500,
    fetchPolicy: "cache-and-network",
	context: { clientName: 'hack' }
  });

  if (loading) return <React.Fragment />;

  if (error) return <React.Fragment />;

  const {coordsSet1, coordsSet2} = mungeData(data, props.devicesSelected);

  console.log("coordsSet1", coordsSet1)

  return (
    <React.Fragment>
      {coordsSet1.length && coordsSet1.map((coordinate, index) => (
        <MapNativeComponent.Marker
            key={coordinate.timeStamp}
            coordinate={{ latitude: coordinate.lat, longitude: coordinate.lon }}
            pinColor={'#000000'}>
        </MapNativeComponent.Marker>        
      ))}

      {coordsSet2.length && coordsSet2.map((coordinate, index) => (
        <MapNativeComponent.Marker
            key={coordinate.timeStamp}
            coordinate={{ latitude: coordinate.lat, longitude: coordinate.lon }}
            pinColor={'#00ff00'}>
        </MapNativeComponent.Marker>        
      ))}

    </React.Fragment>
  );
}

{/* <View  >
                <Image source={require('../assets/images/tahoe.png')} style={{ width: 120, height: 60, borderRadius: 30 }}/>
              </View> */}

function MapNative(props) {

  console.log("MapNative", props);

  const [region, setRegion] = React.useState({
    latitude: 37.74621582,
    longitude: -122.42941284,
    latitudeDelta: 0.100,
    longitudeDelta: 0.100
  });

  return (
      <MapNativeComponent.default style={styles.map} 
                  showsUserLocation
                  loadingEnabled
                  region={region}
                  mapType={"standard"}
                  showsMyLocationButton={true}
                  showsCompass={true}
                  showsScale={true}
                  provider={"google"}
                  showsIndoors={true}>
          <LoadMarkers devicesSelected={props.devicesSelected}/>
      </MapNativeComponent.default>
  )
};

//                  customMapStyle={mapStyle}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    //backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  map: {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
  },
  splitScreen: {
    display: 'flex',
    flexDirection: 'row',
  },
  topPane: {
      width: '50%',
  },
  bottomPane: {
      width: '50%',
  },
});



const Stack = createStackNavigator();

const Container = styled.View`
	backgroundColor: ${({ theme }) => theme.components.screen.background};
	min-height: 100%;
	padding: 10px;
`;


const MapContainer = (props) => {
	return (
    <Container>
      {!supportEmbeddedMapView && <MapBoxWeb devicesSelected={props.devicesSelected}/>}
      {supportEmbeddedMapView && <MapNative devicesSelected={props.devicesSelected}/>}
    </Container>
	);
};

const MapSplitterContainer = () => {
  const [isSelected1, setSelection1] = useState(true);
  const [isSelected2, setSelection2] = useState(false);

  var devicesSelected = [];

  if( isSelected1 || supportEmbeddedMapView ) {
    devicesSelected.push( '9205-Tracker-SanFrancisco-1' );
  }

  if( isSelected2 || supportEmbeddedMapView ) {
    devicesSelected.push( '9205-Tracker-SanFrancisco-3' );
  }

  console.log("devicesSelected", devicesSelected);

	return (
    <React.Fragment>
      {!supportEmbeddedMapView && (
      <SplitPaneComponent.default primary="first" split="vertical" minSize="15%">
        <DeviceScreen isChecked1={isSelected1} setSelection1={setSelection1} isChecked2={isSelected2}  setSelection2={setSelection2}/>
        <MapContainer devicesSelected={devicesSelected}/>
      </SplitPaneComponent.default>
      )}

      {supportEmbeddedMapView && (
        <MapContainer devicesSelected={devicesSelected}/>
      )}
    </React.Fragment>
	);
};


export const MapScreen: React.FC = () => (
	<Stack.Navigator
		screenOptions={{
			header: () => (
				<ScreenHeader
					title='Live Tracking'
				/>
			)
		}}
	>
		<Stack.Screen name="Connectivity" component={MapSplitterContainer} />
	</Stack.Navigator>
);
