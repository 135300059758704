import React, { useState } from 'react';
import { View } from 'react-native';
import { Col, Row } from 'react-native-easy-grid';
import colors from '../../colors/colors';
import MoreIcon from '../../icons/more';
import { Paragaraph } from '../../type/paragraph/styled';
import { Title } from '../../type/title/styled';
import { ConnectivityLevelRows, DataTableHeaders } from '../';
import { Column, SortingProps } from '../types';
import { CellProps } from './types';
import moment from 'moment';
import * as Styled from './styled';


const DeviceCell: React.FC<CellProps> = ({ device }) => (
	<Row style={{ paddingLeft: 8, }}>
		<Col size={10}>
			<Title size='small' style={{ color: colors.cyan['500'] }}>
				{device.deviceName}
			</Title>
			<Paragaraph size='small' style={{ fontFamily: 'Inter_600SemiBold', color: '#BBBDC4' }}>SN {device.deviceSerialNumber}</Paragaraph>
		</Col>
		<Col style={{ alignItems: 'center' }}>
			<Styled.OnlineDot style={{ position: 'absolute', top: 5 }} />
			<MoreIcon
				size='28px'
				style={{ position: 'absolute', top: 15 }}
			/>
		</Col>
	</Row>
);

const LastHeardCell: React.FC<CellProps> = ({ device }) => {
	if (device.lastHeard) {
		const date = moment(device.lastHeard).format('YYYY-MM-DD');
		const hour = moment(device.lastHeard).format('HH:MM');
		return (
			<View>
				<Styled.CellText>{date}</Styled.CellText>
				<Styled.CellText>{hour} UTC</Styled.CellText>
			</View>
		);
	} else {
		return (
			<View>
				<Styled.CellText>-</Styled.CellText>
			</View>
		);
	}
};

const ConnectivityCell: React.FC<CellProps> = ({ device }) => (
	<Row>
		<Col size={3}>
			<Styled.CellText fontFamily='Inter_600SemiBold'>
				{device.connectivityLevel}
			</Styled.CellText>
			{
				device.connectivityLevel !== 'No Data'
					? (
						<Styled.CellText fontFamily='Inter_600SemiBold'>
							{device.connectivityPercent}%
						</Styled.CellText>
					)
					: null
			}
		</Col>
		<Col style={{ alignItems: 'center' }}>
			<MoreIcon
				size='28px'
				style={{ position: 'absolute', top: 15 }}
			/>
		</Col>
	</Row>
);

const columns: Column[] = [
	{
		name: 'Device',
		size: 3,
		displaySortIcon: false,
		render(device) {
			return <DeviceCell device={device} />;
		}
	},
	{
		name: 'Last Heard',
		size: 1,
		displaySortIcon: false,
		render(device) {
			return <LastHeardCell device={device} />;
		}
	},
	{
		name: 'Connectivity',
		size: 1,
		displaySortIcon: false,
		render(device) {
			const ConnectivityColors = {
				'No Data': colors.fuchsia['200'],
				'Poor': colors.fuchsia['100'],
				'Fair': colors.teal['100'],
				'Good': colors.teal['200']
			};
			return (
				<Styled.ConnectivityCell backgroundColor={ConnectivityColors[device.connectivityLevel]}>
					<ConnectivityCell device={device} />
				</Styled.ConnectivityCell>
			);
		}
	},
	{
		name: 'Connectivity Type',
		size: 1,
		displaySortIcon: false,
		render(device) {
			return (
				<Styled.CellText>{device.connectivityType}</Styled.CellText>
			);
		}
	},
	{
		name: 'Operator',
		size: 1,
		displaySortIcon: false,
		render(device) {
			return (
				<Styled.CellText>{device.operator}</Styled.CellText>
			);
		}
	},
	{
		name: 'Cell Area Location',
		size: 1,
		displaySortIcon: false,
		render(device) {
			return (
				<Styled.CellText>{device.cellAreaLocation}</Styled.CellText>
			);
		}
	},
	{
		name: 'Device Group',
		size: 1,
		displaySortIcon: false,
		render(device) {
			return (
				<Styled.CellText>{device.groups}</Styled.CellText>
			);
		}
	},
	{
		name: 'Product',
		size: 1,
		displaySortIcon: false,
		render(device) {
			return (
				<Styled.CellText>{device.product}</Styled.CellText>
			);
		}
	},
	{
		name: 'DeviceOS',
		size: 1,
		displaySortIcon: false,
		render(device) {
			return (
				<Styled.CellText>{device.deviceOSVersion}</Styled.CellText>
			);
		}
	},
	{
		name: 'Firmware',
		size: 1,
		displaySortIcon: false,
		render(device) {
			return (
				<Styled.CellText>{device.firmwareVersion}</Styled.CellText>
			);
		}
	}
];

const ConnectivityLevelDataTable: React.FC = () => {
	const [onSort, setOnSort] = useState<SortingProps>({
		column: 'deviceName',
		order: 'asc'
	});

	return (
		<View style={{ marginLeft: 17, marginRight: 17 }}>
			<DataTableHeaders
				columns={columns}
				onSort={setOnSort}
			/>
			<ConnectivityLevelRows
				columns={columns}
				onSort={onSort}
			/>
		</View>
	);
};

export default ConnectivityLevelDataTable;
