import React from 'react';
import { createNavBarNavigator } from '../nav-bar-navigator/nav-bar-navigator';
import { NAV_ROUTES } from '../routes';

const NavBar = createNavBarNavigator();

export const MainNavigator: React.FC = () => {
	return (
		<NavBar.Navigator>
			{NAV_ROUTES.map((route) => (
				<NavBar.Screen
					key={route.name}
					name={route.name}
					component={route.component}
				/>
			))}
		</NavBar.Navigator>
	);
};
