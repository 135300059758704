// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createGlobalStyle } from 'styled-components';
import { PlatformOSType } from 'react-native';


export default function createGlobalStyles(os: PlatformOSType): React.FC {
	if (os === 'web'){
		return (
			// TODO (mirande): typescript doesn't like this
			// src/components/ui/global-styles.ts(8,3): error TS2322:
			// Type 'GlobalStyleComponent<{}, DefaultTheme>' is not assignable
			// to type 'FC<{}>'.
			// Type 'GlobalStyleComponent<{}, DefaultTheme>' provides no match
			// for the signature '(props: { children?: ReactNode; }, context?: any):
			// ReactElement<any, any> | null'.
			createGlobalStyle`
				body {
					display: flex;
				}
			`
		);
	}

	return () => null;
}

