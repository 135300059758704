import React from 'react';
import Icon from './icon';
import { IconProps } from './types';

const ICON_PATH = 'M20.2939 18.5786H19.3905L19.0703 18.2699C20.191 16.9663 20.8656 15.2739 20.8656 13.4328C20.8656 9.32762 17.538 6 13.4328 6C9.32762 6 6 9.32762 6 13.4328C6 17.538 9.32762 20.8656 13.4328 20.8656C15.2739 20.8656 16.9663 20.191 18.2699 19.0703L18.5786 19.3905V20.2939L24.2962 26L26 24.2962L20.2939 18.5786V18.5786ZM13.4328 18.5786C10.5855 18.5786 8.28702 16.2802 8.28702 13.4328C8.28702 10.5855 10.5855 8.28702 13.4328 8.28702C16.2802 8.28702 18.5786 10.5855 18.5786 13.4328C18.5786 16.2802 16.2802 18.5786 13.4328 18.5786Z';

const SearchIcon: React.FC<IconProps> = ({ size, color }) => {
	return <Icon path={ICON_PATH} size={size} color={color} />;
};

export default SearchIcon;
