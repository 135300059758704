import styled from 'styled-components/native';
import Type from '../type';
import { LabelProps } from './types';


export const Label = styled(Type)<LabelProps>`
	fontSize: 10px;
	fontFamily: ${({ type }) => {
		switch (type) {
			case 'regular': return 'Inter_400Regular';
			case 'bold': return 'Inter_600SemiBold';
		}
	}};
`;
