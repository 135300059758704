import add from 'date-fns/add';
import getMinutes from 'date-fns/getMinutes';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import startOfHour from 'date-fns/startOfHour';
import sub from 'date-fns/sub';
import formatDate from 'date-fns/format';
import { ConnectivityInfo, Domain } from './types';

export const filterBetween = (
	arr: ConnectivityInfo[],
	start: Date,
	end: Date
): ConnectivityInfo[] =>
	arr.filter(
		(info) =>
			isBefore(new Date(info.time), end) && isAfter(new Date(info.time), start)
	);

const ceilToHalfHourInterval = (date: Date): Date =>
	getMinutes(date) > 30
		? add(startOfHour(date), { hours: 1 })
		: add(startOfHour(date), { minutes: 30 });

export const getLast12HoursDomain = (items: Date[]): Domain => {
	const lastDate = ceilToHalfHourInterval(items[items.length - 1]);
	return { start: sub(lastDate, { hours: 12 }), end: lastDate };
};
export const getLastDayDomain = (items: Date[]): Domain => {
	const lastDate = ceilToHalfHourInterval(items[items.length - 1]);
	return { start: sub(lastDate, { days: 1 }), end: lastDate };
};
export const getLastWeekDomain = (items: Date[]): Domain => {
	const lastDate = ceilToHalfHourInterval(items[items.length - 1]);
	return { start: sub(lastDate, { weeks: 1 }), end: lastDate };
};
export const getLastMonthDomain = (items: Date[]): Domain => {
	const lastDate = ceilToHalfHourInterval(items[items.length - 1]);
	return { start: sub(lastDate, { months: 1 }), end: lastDate };
};

export const extractConnectivityInfoDate = (info: ConnectivityInfo): Date =>
	new Date(info.time);

export const formatTick = (tick: number): string => {
	const date = ceilToHalfHourInterval(new Date(tick));

	return `${formatDate(date, 'MMM d')}\n${formatDate(date, 'hh:mm')}`;
};
