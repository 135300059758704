import styled from 'styled-components/native';

export const Container = styled.SafeAreaView`
	flex-direction: row;
	justify-content: space-between;
	padding: 16px;
	background-color: ${(props) =>
		props.theme.components.screenHeader.background};
`;

export const Leading = styled.View`
	flex-direction: row;
	align-items: center;
`;

export const Trailing = styled.View`
	flex-direction: row;
	align-items: center;
`;
