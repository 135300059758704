import React from 'react';
import Icon from './icon';
import { IconProps } from './types';


const ICON_PATH = 'M8.5 13C7.125 13 6 14.125 6 15.5C6 16.875 7.125 18 8.5 18C9.875 18 11 16.875 11 15.5C11 14.125 9.875 13 8.5 13ZM23.5 13C22.125 13 21 14.125 21 15.5C21 16.875 22.125 18 23.5 18C24.875 18 26 16.875 26 15.5C26 14.125 24.875 13 23.5 13ZM16 13C14.625 13 13.5 14.125 13.5 15.5C13.5 16.875 14.625 18 16 18C17.375 18 18.5 16.875 18.5 15.5C18.5 14.125 17.375 13 16 13Z';

const MoreIcon: React.FC<IconProps> = ({ size, color, style }) => {
	return <Icon style={style} path={ICON_PATH} size={size} color={color} />;
};

export default MoreIcon;
